<script lang="ts">
  import { afterNavigate } from "$app/navigation";
  import { siteSettings } from "$lib/stores/siteSettings";

  export let bottomColor = "secondary";

  $: showBoxes = !$siteSettings.disableBGBoxes;

  let scrollDiv: HTMLElement;
  afterNavigate(() => {
    scrollDiv?.scrollTo({ left: 0, top: 0, behavior: "instant" });
    if (window.location.hash) {
      const deepLinkedElement = document.getElementById(
        window.location.hash.substring(1)
      );
      deepLinkedElement?.scrollIntoView();
    }
  });
</script>

<div
  class="fancy-bg"
  class:secondary={bottomColor === "secondary"}
  class:primary={bottomColor === "primary"}
  class:accent={bottomColor === "accent"}
  class:info={bottomColor === "info"}
>
  <div class:show-boxes={showBoxes} bind:this={scrollDiv}>
    <slot />
  </div>
</div>

<style lang="scss">
  .primary {
    background-image: linear-gradient(
      darken($primary, 20%),
      darken($primary, 5%)
    );
  }
  .secondary {
    background-image: linear-gradient(
      darken($primary, 20%),
      darken($secondary, 15%)
    );
  }
  .accent {
    background-image: linear-gradient(
      0,
      desaturate(darken($accent, 30%), 35%),
      darken($primary, 20%) 400px
    );
  }
  .info {
    background-image: linear-gradient(
      darken($primary, 20%),
      darken($info, 10%)
    );
  }

  .fancy-bg {
    height: 100%;
    width: 100%;
    > div {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: clip;
      scroll-behavior: smooth;
      scroll-padding: 2rem;
    }
  }
  .show-boxes {
    background-image: url("/svg/bg-hexes.svg");
  }
</style>
